import { graphql, Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { t } from "i18next";
import * as React from "react";
import { Trans } from "react-i18next";
import ButtonGradient from "../components/ButtonGradient";
import Card from "../components/Card";
import CardsGrid from "../components/CardsGrid";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

// markup
const AllCareersPage = ({ data }: { data: any }) => {
  const { language } = useI18next();
  // all posts
  const offers = data.allGraphCmsJobOffer.nodes;

  return (
    <>
      <SEO title={t("Career")} />
      <Layout>
        <section className="">
          <header className="mb-10 text-center">
            <h2 className="text-center">
              <Trans>Praca w Stenografie</Trans>
            </h2>
            <p>
              Dołącz do zespołu ludzi, którzy mają wpływ!
              <br />
              Być może odnajdziesz się tu jak nigdzie wcześniej?
              <br /> 😉
            </p>
          </header>
          {/* all job offers */}
          <CardsGrid>
            {offers.map((offer: any) => {
              const { slug, title } = offer;

              return (
                <li key={slug} className="w-full">
                  <Card title={title} slug={slug} btnText="Zobacz"></Card>
                </li>
              );
            })}
          </CardsGrid>
        </section>
        <ButtonGradient
          text={"Poznaj historie naszych pracowników"}
          to="/blog"
          aClasses="mx-auto w-fit my-16"
        />
      </Layout>
    </>
  );
};

export default AllCareersPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allGraphCmsJobOffer {
      nodes {
        title
        slug
      }
    }
  }
`;
